/**
 * Imports
 */
import { ColumnsType } from 'antd/lib/table/interface';
import { SoftPos } from 'types/softpos';
import { CreditCard, Icon, Layout, Table, Text } from 'components';
import { usePostingsApi } from 'features/settlements';
import { postingsSelector } from 'features/settlements/redux/selectors';
import moment from 'moment';
import React, { forwardRef } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DATE_FORMATS, formatCurrency } from 'utils';
import { Tag, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Firebase } from 'types/firebase';
import { terminalCollectionSelector } from 'features/user/redux/selectors';

/**
 * Types
 */
export interface PostingsTableProps {}

/**
 * Table columns
 */
export const columns = (
  terminalCollection: Firebase.Terminal[],
): ColumnsType<SoftPos.Posting> => [
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.card_type" />,
    dataIndex: 'scheme',
    key: 'scheme',
    render: (scheme: SoftPos.Scheme) => {
      return <>{scheme && <CreditCard card={scheme} rounded />}</>;
    },
  },
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.date" />,
    dataIndex: 'processedAt',
    key: 'processedAt',
    render: (processedAt: string) => {
      return (
        <Text size="ui2">{moment(processedAt).format(DATE_FORMATS.date)}</Text>
      );
    },
  },
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.last_four" />,
    dataIndex: 'lastFour',
    key: 'lastFour',
    render: (lastFour: string) => {
      return <Text size="ui2">**** {lastFour}</Text>;
    },
  },
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.terminal_id" />,
    dataIndex: 'terminalId',
    key: 'terminalId',
    render: (terminalId: string) => {
      return <Text size="ui2">{terminalId}</Text>;
    },
  },
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.type" />,
    dataIndex: 'type',
    key: 'type',
    render: (type: SoftPos.PostingType, record: SoftPos.Posting) => {
      return (
        <>
          {type === 'credit_note' && (
            <>
              <Layout direction="row">
                <Tag color="blue">
                  <Trans i18nKey="SettlementDetails.table_tags.posting_type_credit" />
                </Tag>
                {record.description && (
                  <Tooltip title={record.description}>
                    <div>
                      <Icon icon={<InfoCircleOutlined />} />
                    </div>
                  </Tooltip>
                )}
              </Layout>
            </>
          )}
          {type === 'refund_transaction' && (
            <Tag color="red">
              <Trans i18nKey="SettlementDetails.table_tags.posting_type_refund" />
            </Tag>
          )}
          {type === 'sales_transaction' && (
            <Tag color="green">
              <Trans i18nKey="SettlementDetails.table_tags.posting_type_trxn" />
            </Tag>
          )}
        </>
      );
    },
  },
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.net_amount" />,
    dataIndex: 'settlementAmount',
    key: 'settlementAmount',
    render: (settlementAmount: number, record: SoftPos.Posting) => {
      return (
        <Text size="ui2">
          {formatCurrency(settlementAmount / 100, record.currencyCode)}
        </Text>
      );
    },
  },
  {
    title: () => <Trans i18nKey="SettlementDetails.table_header.fee_amount" />,
    dataIndex: 'settlementFeeAmount',
    key: 'settlementFeeAmount',
    render: (settlementFeeAmount: number, record: SoftPos.Posting) => {
      return (
        <Text size="ui2">
          {formatCurrency(settlementFeeAmount / 100, record.currencyCode)}
        </Text>
      );
    },
  },
  {
    title: () => (
      <Trans i18nKey="SettlementDetails.table_header.gross_amount" />
    ),
    dataIndex: 'transactionAmount',
    key: 'transactionAmount',
    render: (transactionAmount: number, record: SoftPos.Posting) => {
      return (
        <Text size="ui2">
          {formatCurrency(transactionAmount / 100, record.currencyCode)}
        </Text>
      );
    },
  },
  {
    title: () => <Trans i18nKey="Transactions.table_header.terminal_name" />,
    dataIndex: 'terminalId',
    key: 'terminalId',
    render: (terminalId: string) => {
      const name = terminalCollection.find((terminal) => {
        if (terminal.terminalId.startsWith('ti_')) {
          return terminal.softpayConfig?.onlineTerminalId === terminalId;
        } else {
          return terminal.terminalId === terminalId;
        }
      })?.name;
      return <Text size="ui2">{name}</Text>;
    },
  },
  {
    title: () => <Trans i18nKey="Transactions.table_header.note" />,
    dataIndex: 'note',
    key: 'note',
    render: (note: string) => {
      return <Text size="ui2">{note}</Text>;
    },
  },
];

/**
 * PostingsTable component
 */
const PostingsTable = forwardRef<HTMLDivElement, PostingsTableProps>(
  (props, ref) => {
    const params = useParams<{ id: string }>();
    const postings = useSelector(postingsSelector);
    const { isFecthing } = usePostingsApi(params.id);
    const terminalCollection = useSelector(terminalCollectionSelector);

    React.useEffect(() => {}, [postings]);

    return (
      <div ref={ref}>
        <Table<SoftPos.Posting>
          columns={columns(terminalCollection)}
          dataSource={postings}
          loading={isFecthing}
          rowKey={(record) => record.postingIndex}
          pagination={false}
        />
      </div>
    );
  },
);

/**
 * Exports
 */
export { PostingsTable };
